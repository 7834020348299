export default function (media) {
  switch (media.type) {
    case "Season":
      const { tvShowSeason, tvShowSeasons } = media;
      if (tvShowSeason) {
        return tvShowSeason.name;
      }

      if (Array.isArray(tvShowSeasons) && tvShowSeasons.length > 0) {
        return tvShowSeasons[0].name;
      }

      return media.name || media.seriesName;
    case "Series":
      return media.tvShowSeries?.name || media.name || media.seriesName;
    case "Movie":
      return (media.aggregatedVideo || media).name;
    case "LiveProgram":
      if (
        Object.keys(media).includes("extendedProgram") &&
        media.extendedProgram.name
      ) {
        return media.extendedProgram.name;
      }
    default:
      return media.name;
  }
}
