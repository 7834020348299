<template>
  <div
    :class="size"
    class="plus relative flex justify-center ps-1 items-center backdrop-blur-sm bg-brand-red-surface rounded-full border border-brand-red-border"
  >
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 44 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.9974 10.7994C32.2909 11.3036 31.4018 11.5557 30.3302 11.5557C29.0819 11.5557 27.7336 11.0984 26.2852 10.1838L27.398 8.65361C28.0457 9.09918 28.9112 9.32197 29.9946 9.32197C31.1486 9.32197 31.7256 8.88226 31.7256 8.00283C31.7256 7.47517 31.0603 6.97096 29.7296 6.49021C27.7042 5.76321 26.6914 4.61995 26.6914 3.06042C26.6914 2.11064 27.0447 1.35432 27.7513 0.791488C28.4107 0.263829 29.2998 0 30.4185 0C31.6785 0 32.8679 0.38695 33.9866 1.16085C33.7746 1.47745 33.4214 1.98752 32.9268 2.69106C31.9376 2.28066 31.0897 2.07546 30.3832 2.07546C29.4882 2.07546 29.0407 2.40964 29.0407 3.07801C29.0407 3.35943 29.1614 3.59688 29.4028 3.79035C29.6442 3.98382 30.1182 4.22127 30.8248 4.50269C32.0495 4.97172 32.9356 5.49352 33.4832 6.06808C34.0308 6.64264 34.3046 7.32273 34.3046 8.10836C34.3046 9.28094 33.8689 10.178 32.9974 10.7994ZM2.50098 11.5563V7.41772C2.5128 7.44161 2.6754 7.47147 2.98876 7.5073C3.30212 7.54313 3.5534 7.56105 3.7426 7.56105C4.60582 7.56105 5.36853 7.34009 6.03073 6.89816C7.0595 6.20541 7.57388 5.09463 7.57388 3.56581C7.57388 1.18897 6.12532 0.000553385 3.22821 0.000553385C2.80251 0.000553385 2.19057 0.0304132 1.39239 0.0901327C0.594204 0.149852 0.130074 0.197628 0 0.23346V11.5563H2.50098ZM3.64154 5.77796C3.26489 5.77796 2.94205 5.71211 2.67301 5.58042V2.34805C2.73758 2.26425 3.05504 2.22235 3.6254 2.22235C4.47555 2.22235 4.90063 2.79699 4.90063 3.94628C4.90063 4.49698 4.78494 4.93993 4.55357 5.27514C4.3222 5.61035 4.01819 5.77796 3.64154 5.77796ZM15.594 9.58964V11.5556H8.91113V0.444336H11.4172V9.58964H15.594ZM20.9396 12.0001C23.9097 12.0001 25.3948 10.3399 25.3948 7.01949V0.444366H22.8876V7.32406C22.8876 9.03204 22.2383 9.88603 20.9396 9.88603C19.6769 9.88603 19.0456 9.03204 19.0456 7.32406V0.444366H16.4843V7.01949C16.4843 10.3399 17.9694 12.0001 20.9396 12.0001Z"
        fill="white"
        fill-opacity="0.87"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.9678 7.12495V9.93738H41.0985V7.12495H43.7623V4.87492H41.0985V2.06226H38.9678V4.87492H36.3047V7.12495H38.9678Z"
        fill="#D02D2E"
      />
    </svg>
  </div>
</template>

<script setup>
const props = defineProps({
  size: { type: String, default: () => "big" },
});

const width = computed(() => {
  switch (props.size) {
    case "big":
      return "44px";
    case "medium":
      return "30px";
    default:
      return "22px";
  }
});

const height = computed(() => {
  switch (props.size) {
    case "big":
      return "12px";
    case "medium":
      return "8px";
    default:
      return "6px";
  }
});
</script>

<style lang="scss">
.plus {
  height: auto;

  &.big {
    width: 58px;
    padding: 6px 8px;
  }

  &.medium {
    width: 40px;
    padding: 4px 6px;
  }

  &.small {
    width: 32px;
    padding: 4px 6px;
  }
}
</style>
